import React from 'react'
import PrivacyPolicy from '../components/PrivacyPolicy'

const PolitykaPage = () => {
  return (
    <PrivacyPolicy />
  )
}

export default PolitykaPage
